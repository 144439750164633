import { CurrentState } from '@gth-legacy/components/event-info/state/state';
import { createReducer, on } from '@ngrx/store';

import * as PlacesAction from './actions';
import { PlacesState } from './state';

export const initialPlacesState: PlacesState = {
  status: CurrentState.Pending,
  places: [],
  events: [],
};

export const placesReducer = createReducer(
  initialPlacesState,
  on(PlacesAction.placesLoadListSuccess, (state, data) => ({
    ...state,
    places: data.places,
    status: CurrentState.Success,
  })),
  on(PlacesAction.placesLoadEventsByPlaceIdSuccess, (state, data) => {
    return {
      ...state,
      events: data.events,
      status: CurrentState.Success,
    };
  }),
);
